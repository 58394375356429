:root {
  --body-background: hsl(128, 14%, 95%);
  --body-color: #606c76;
  --color-primary: hsl(128, 54%, 55%);
}

body {
  @apply font-sans font-normal tracking-normal leading-relaxed;
  @apply flex flex-col min-h-screen;
  background: var(--body-background);
  box-shadow: inset 0 0 0 1rem theme("colors.white");
}

main {
  @apply flex-auto;
}

.container-auto {
  @apply mx-auto px-8 sm:px-6 py-20;
}

.prose {
  @apply mx-auto;
}
